<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      base_URL: '',
    };
  },
  created() {
    const protocol: string = window.location.protocol;
    const domaine: string = window.location.hostname;
    let port: string | number = '';

    if (window.location.port !== "" && window.location.port !== null) {
      port = ":" + window.location.port;
    }

    this.base_URL = `${protocol}//${domaine}${port}`;
  }
});
</script>
