import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import LoginView from '../views/auth/LoginView.vue'
import A2FView from '../views/auth/A2FView.vue'
import SellersView from '../views/gestion_app/SellersView.vue'
import BillingView from '../views/gestion_app/BillingView.vue'
import StatisticsView from '../views/gestion_app/StatisticsView.vue'
import { authGuard_for_gestion_app } from '../_helpers/auth-guard_for_gestion_app'
import { authGuard_for_A2F } from '../_helpers/auth-guard'



const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: {name : 'Sellers'},
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/',
    redirect: {name : 'Login'},
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/A2F',
    name: 'A2F',
    component: A2FView,
    beforeEnter : authGuard_for_A2F
  },
  {
    path: '/sellers',
    name: 'Sellers',
    component: SellersView,
    beforeEnter : authGuard_for_gestion_app
  },
  {
    path: '/billing',
    name: 'Billing',
    component: BillingView,
    beforeEnter : authGuard_for_gestion_app
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: StatisticsView,
    beforeEnter : authGuard_for_gestion_app
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router