<template>
    <NavView />
    <h3 class="statistics_view_title">Statistiques :</h3>   
    <div id="select_periods">
        <p>Sélectionner une date :</p>
        <label for="start_period_date">Du </label>
        <input type ="date" name="start_period_date" id="start_period_date" min="2021-01-01" max="2050-12-31" v-bind:value="startPeriodDate" />
        <label for="end_period_date"> au </label>
        <input type ="date" name="end_period_date" id="end_period_date" v-bind:value="endPeriodDate" />
        <button @click="update_date_filter">Confirmer</button>
    </div> 
    <div id="select_sellers_popup">
        <button @click="displaySellersList = !displaySellersList">filtrer commerçant(s)</button>
        <div v-if="displaySellersList" class="popup_overlay">
            <div class="popup">
                <h1>Liste des commerçants</h1>
                <div id="checkall_checkbox">
                    <input type="checkbox" name="checkAll" id="checkAll" v-model="selectAll" @change="updateAllCheckedNames">
                    <label for="checkAll">Tout sélectionner</label>
                </div>
                <div class="popup-content">
                    <ul class="seller-list">
                        <li v-for="(seller, index) in sellersList" :key="seller.id">        
                        <input type="checkbox" name="checkbox_seller" :id="'checkbox_' +index" :value= "seller.techName" v-model="seller.isChecked" :checked="checkedNames.includes(seller.techName)" @change="updateCheckedNames(seller)"> 
                        <label :for="'checkbox_' +index">{{ seller.techName }}</label>  <br>
                        </li>
                    </ul>
                </div>
                <div class="popup-button-container">
                    <button class="popup-button" @click="confirm_seller(); displaySellersList = !displaySellersList">Confirmer</button>
                </div>
            </div>
        </div>
    </div> 
    <div v-if="feesData && Object.keys(feesData).length > 0 && displayChart">
        <p>Total des frais : {{ totalFees}} €</p>
        <p>Total des frais de livraison : {{ totalFeesDelivery }} €</p>
        <p>Total des frais de transactions : {{ totalFeesTransactions }} €</p>
        <p>Total des autres frais : {{ totalFeesOthers }} €</p>
        <!-- Component for chart with feesData send to the component -->
        <div class="charts">
            <feesBarChart :feesData="feesDataForChart" ref="feesBarChart"/>
            <feesLineChart :feesData="feesDataForChart"/>
        </div>
    </div>   
    <div v-if="feesDataSolulive && Object.keys(feesDataSolulive).length > 0 && displayChart">
        <p>Total des frais de Solulive : {{ soluliveTotalFees }} €</p>
        <p>Total des frais de livraison de Solulive: {{ soluliveTotalFeesDelivery  }} €</p>
        <p>Total des frais de SMS de Solulive : {{ soluliveTotalFeesSms  }} €</p>
        <p>Total des frais de GoCardLess de Solulive : {{ soluliveTotalFeesGoCardLess  }} €</p>
        <p>Total des frais de SendCloud Label de Solulive : {{ soluliveTotalFeesSendCloudLabel  }} €</p>
        <!-- Component for chart with feesData send to the component -->
        <div class="charts">
            <soluliveFeesBarChart :feesData="soluliveFeesDataForChart"/>
            <soluliveFeesLineChart :feesData="soluliveFeesDataForChart"/>
        </div>
    </div>  
    <div v-if="soluliveGrossMarginForChart && Object.keys(soluliveGrossMarginForChart).length > 0 && displayChart">
        <p>Total de marge brut : {{ totalGrossMargin }} €</p>
        <!-- Component for chart with feesData send to the component -->
        <div class="charts">
            <soluliveGrossMarginBarChart :feesData="soluliveGrossMarginForChart"/>
            <soluliveGrossMarginLineChart :feesData="soluliveGrossMarginForChart"/>
        </div>
    </div>  
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import NavView from '@/components/NavView.vue';
import axios, { AxiosResponse, AxiosError } from 'axios';
import base_URL from '@/App.vue';
import feesBarChart from '@/components/feesBarChart.vue';
import feesLineChart from '@/components/feesLineChart.vue';
import soluliveFeesBarChart from '@/components/soluliveFeesBarChart.vue';
import soluliveFeesLineChart from '@/components/soluliveFeesLineChart.vue';
import soluliveGrossMarginBarChart from '@/components/soluliveGrossMarginBarChart.vue';
import soluliveGrossMarginLineChart from '@/components/soluliveGrossMarginLineChart.vue';

//javascript library to calculate date interval
import { eachDayOfInterval, startOfDay, endOfDay, getTime } from 'date-fns';

interface ErrorResponse {
  status : string
  error_code : string
}

type fees = {
    feesDelivery: fee[],
    feesTransactions: fee[],
    feesOthers: fee[]
}

type fee = {
    techName : string,
    amountToPayHt : number,
    timestampFee : number
}

type feesTotals = {
    totalFees: number,
    totalFeesDelivery : number,
    totalFeesOthers : number
    totalFeesTransactions: number,    
}

type feesData = {
    fees: fees,
    feesTotals: feesTotals
}

interface GetInfosOfFeesSuccessResponse {
  status : string,
  token_for_gestion : string | null,
  feesData : feesData;
}

type feesSolulive = {
    feesDeliverySolulive: fee[],
    feesSmsSolulive: fee[],
    feesGoCardLessSolulive: fee[],
    feesSendCloudLabelSolulive: fee[],
}

type feesTotalsSolulive = {
    totalFeesSolulive: number,
    totalFeesDeliverySolulive : number,
    totalFeesSmsSolulive : number, 
    totalFeesGoCardLessSolulive : number, 
    totalFeesSendCloudLabelSolulive : number, 
}

type feesDataSolulive = {
    feesSolulive: feesSolulive,
    feesTotalsSolulive: feesTotalsSolulive
}

interface GetInfosOfSoluliveFeesSuccessResponse {
  status : string,
  token_for_gestion : string | null,
  feesDataSolulive : feesDataSolulive;
}

type feesOfTheDay = {
    dateString: string,
    totalFeesOfTheDay : number,
    totalFeesDeliveryOfTheDay : number,
    totalFeesTransactionsOfTheDay : number,
    totalFeesOthersOfTheDay : number,
    totalCumulatedFees : number,
    totalCumulatedFeesDelivery : number,
    totalCumulatedFeesTransactions : number,
    totalCumulatedFeesOthers : number,
}

type soluliveFeesOfTheDay = {
    dateString: string,
    soluliveTotalFeesOfTheDay : number,
    soluliveTotalFeesDeliveryOfTheDay : number,
    soluliveTotalFeesSmsOfTheDay : number,
    soluliveTotalFeesGoCardLessOfTheDay : number,
    soluliveTotalFeesSendCloudLabelOfTheDay : number,
    soluliveTotalCumulatedFees : number,
    soluliveTotalCumulatedFeesDelivery : number,
    soluliveTotalCumulatedFeesSms : number,
    soluliveTotalCumulatedFeesGoCardLess : number,
    soluliveTotalCumulatedFeesSendCloudLabel : number,
}

type soluliveGrossMarginOfTheDay = {
    dateString: string,
    grossMarginOfTheDay : number,
    grossMarginCumulated : number,
}

type Seller = {
    id: number;
    techName: string;
    isChecked: boolean
}

interface GetSellerListSuccessResponse {
  status : string,
  sellersList : Seller[],
  token_for_gestion : string | null,
}
export default defineComponent({
    name: "StatisticsView",
    components: { NavView, feesBarChart, feesLineChart, soluliveFeesBarChart, soluliveFeesLineChart, soluliveGrossMarginBarChart, soluliveGrossMarginLineChart},
    data(){
        return{
            start_timestamp : 0,
            end_timestamp : 0,
            feesData : {} as feesData,
            feesDataSolulive : {} as feesDataSolulive,
            feesDataForChart : [] as feesOfTheDay[],
            soluliveFeesDataForChart : [] as soluliveFeesOfTheDay[],
            soluliveGrossMarginForChart : [] as soluliveGrossMarginOfTheDay[],
            totalFees: 0,
            totalFeesDelivery:0,
            totalFeesTransactions:0,
            totalFeesOthers: 0,
            soluliveTotalFees: 0,
            soluliveTotalFeesDelivery:0,
            soluliveTotalFeesSms: 0,
            soluliveTotalFeesGoCardLess: 0,
            soluliveTotalFeesSendCloudLabel : 0,
            totalGrossMargin : 0,
            startPeriodDate : '' as string,
            endPeriodDate : '' as string,
            displayChart: true,
            displaySellersList : false,
            selectAll: true,
            checkedNames: [] as string[],
            sellersList: [] as Seller[],

        };
    },
    mounted() {
        this.get_active_sellers_list()
        this.get_start_and_end_period_date_default_value()
    },
    methods : {
        get_active_sellers_list() {

            let old_token_for_gestion = localStorage.getItem('token_for_gestion')

            if (old_token_for_gestion) {

                axios.get(`https://newgestion-backend.solulive.com/statistics/get/active_sellers_list`, {
                headers: {
                    Authorization: `Bearer `+ old_token_for_gestion, // Envoyer le token dans l'en-tête Authorization
                },
                })
                .then((response : AxiosResponse<GetSellerListSuccessResponse>) => {this.after_get_active_sellers_list(response)})
                .catch((error : AxiosError<ErrorResponse>) => { this.after_get_active_sellers_list_error(error)})
              
            } else {
                this.$router.push('/login');
            }            
        },
        after_get_active_sellers_list(response : AxiosResponse<GetSellerListSuccessResponse>) {

            const token_for_gestion = response.data.token_for_gestion

            if (token_for_gestion !== undefined && token_for_gestion !== null) {
                localStorage.setItem( 'token_for_gestion', token_for_gestion)
            }

            const sellersList = response.data.sellersList

            if (sellersList.length !==0) {
                this.sellersList = sellersList    
                this.init_ckecked_names()
            }

        },
        after_get_active_sellers_list_error(error : AxiosError<ErrorResponse>) {

            if(error.response?.data.status === "error" ) {
                
                let message : string = error.response?.data.error_code;

                if (message === "token_expired") {

                    alert( "Votre cession est expirée, veuillez vous reconnecter.")
                    localStorage.removeItem( 'token_for_gestion')
                    window.location.reload();

                } else if (message === "invalid_token") {

                    window.location.reload();
                    localStorage.removeItem( 'token_for_gestion')
                    
                } else if (message === "request_failed") {
                    alert('Une erreur est survenue, veuillez réessayer')
                }
            }
        },
        init_ckecked_names(){
            this.sellersList.forEach(seller => {
                this.checkedNames.push(seller.techName)
            });
            // Get infos of fees
            this.get_infos_of_fees()
            this.get_infos_of_solulive_fees()
        },
        confirm_seller(){
            // Get infos of fees
            this.get_infos_of_fees()
            this.get_infos_of_solulive_fees()
        },
        get_start_and_end_period_date_default_value() {
            
            // Get start and end date
            // startDate = first day of the previous month
            // endDate = last day of the previous month

            //TODAY
            let today = new Date();

            const firstDateOfCurrentMonth = new Date(today.getFullYear(), today.getMonth(), 1); // first day of current month

            
            // START OF GET STARTDATE //
            const firstDateOfPreviousMonth = new Date(firstDateOfCurrentMonth);
            // -1 to obtain the first day of the previous month 
            firstDateOfPreviousMonth.setMonth(firstDateOfPreviousMonth.getMonth());

            const firstDayOfPreviousMonth = '01';
            let monthOfPreviousMonth  : number | string = firstDateOfPreviousMonth.getMonth();
            const yearOfPreviousMonth= firstDateOfPreviousMonth.getFullYear();

            if ( monthOfPreviousMonth < 10 ) {
                monthOfPreviousMonth = "0"+monthOfPreviousMonth;
            }

            var startDate = yearOfPreviousMonth+"-"+monthOfPreviousMonth+"-"+firstDayOfPreviousMonth;

            // END OF GET STARTDATE //
            
            // START OF GET ENDDATE //

            let lastDateOfPreviousMonth = new Date(firstDateOfCurrentMonth.getTime() - 1)
            let dayOfCurrentDate : number | string = today.getDate();
            if ( dayOfCurrentDate < 10 ) {
                dayOfCurrentDate = "0"+dayOfCurrentDate;
            }

            let monthOfCurrentDate : number | string = today.getMonth()
            if ( monthOfCurrentDate < 10 ) {
                monthOfCurrentDate = "0"+monthOfCurrentDate;
            }

            let yearOfCurrentDate : number | string = today.getFullYear()

            let lastDayOfPreviousMonth : number | string = lastDateOfPreviousMonth.getDate();
            if ( lastDayOfPreviousMonth < 10 ) {
                lastDayOfPreviousMonth = "0"+lastDayOfPreviousMonth;
            }
            
            var endDate = yearOfCurrentDate+"-"+monthOfCurrentDate+"-"+lastDayOfPreviousMonth;
            // END OF GET ENDDATE //

            // Assign date to vue variables
            this.startPeriodDate = startDate;  
            this.endPeriodDate = endDate;      

            this.get_timestamps_for_filter()
        },
        get_timestamps_for_filter() {

            const startDateArray = this.startPeriodDate.split("-")
            const endDateArray = this.endPeriodDate.split("-")

            //Convert startPeriodDate and endPeriodDate (string) values to Date
            let startDate = new Date()
            startDate.setFullYear(parseInt(startDateArray[0], 10))
            startDate.setMonth(parseInt(startDateArray[1], 10)-1)
            startDate.setDate(parseInt(startDateArray[2], 10))

            let endDate = new Date()
            endDate.setFullYear(parseInt(endDateArray[0], 10))
            endDate.setMonth(parseInt(endDateArray[1], 10)-1)
            endDate.setDate(parseInt(endDateArray[2], 10))

            var startTimestampFilter = this.date_to_timestamp(parseInt(startDateArray[0], 10),parseInt(startDateArray[1], 10),parseInt(startDateArray[2], 10))
            var endTimestampFilter = this.date_to_timestamp(parseInt(endDateArray[0], 10),parseInt(endDateArray[1], 10),parseInt(endDateArray[2], 10))
                
            this.start_timestamp = startTimestampFilter
            this.end_timestamp = endTimestampFilter
            
        },
        get_infos_of_fees() {

            let old_token_for_gestion = localStorage.getItem('token_for_gestion')

            if(old_token_for_gestion) {

                if(this.checkedNames.length >0){

                    // mask chart during the request
                    this.displayChart = false

                    axios.get(`https://newgestion-backend.solulive.com/statistics/get/infos_of_fees`, {
                        headers: {
                            Authorization: `Bearer `+ old_token_for_gestion, // Send the token in the authorization header
                        },
                        params : {
                            startTimestampFilter : this.start_timestamp,
                            endTimestampFilter: this.end_timestamp,
                            checkedNamesFilter: this.checkedNames
                        }
                        })
                        .then((response : AxiosResponse<GetInfosOfFeesSuccessResponse>) => {this.after_get_infos_of_fees(response)})
                        .catch((error : AxiosError<ErrorResponse>) => { this.after_get_infos_of_fees_error(error)})
                } else {
                    alert('Veuillez séléctionner au moins 1 commerçant')
                }
                
            } else {
                this.$router.push('/login');
            }
        },

        after_get_infos_of_fees(response : AxiosResponse<GetInfosOfFeesSuccessResponse>) {

        const token_for_gestion = response.data.token_for_gestion

        if (token_for_gestion !== undefined && token_for_gestion !== null) {
            localStorage.setItem( 'token_for_gestion', token_for_gestion)
        }
        this.feesData = response.data.feesData

        // call method to prepare data for chart
        this.data_for_fees_chart()

        },

        after_get_infos_of_fees_error(error : AxiosError<ErrorResponse>) {

            if(error.response?.data.status === "error" ) {
                
                let message : string = error.response?.data.error_code;

                if (message === "token_expired") {

                    alert( "Votre cession est expirée, veuillez vous reconnecter.")
                    localStorage.removeItem( 'token_for_gestion')
                    window.location.reload();

                } else if (message === "invalid_token") {

                    window.location.reload();
                    localStorage.removeItem( 'token_for_gestion')
                    
                } else if (message === "request_failed") {
                    alert('Une erreur est survenue, veuillez réessayer')
                }
            }
        },

        get_infos_of_solulive_fees() {
            let old_token_for_gestion = localStorage.getItem('token_for_gestion')

            if(old_token_for_gestion ) {

                if(this.checkedNames.length >0) {

                    axios.get(`https://newgestion-backend.solulive.com/statistics/get/infos_of_solulive_fees`, {
                    headers: {
                        Authorization: `Bearer `+ old_token_for_gestion, // Send the token in the authorization header
                    },params : {
                        startTimestampFilter : this.start_timestamp,
                        endTimestampFilter: this.end_timestamp,
                        checkedNamesFilter: this.checkedNames
                    }
                    })
                    .then((response : AxiosResponse<GetInfosOfSoluliveFeesSuccessResponse>) => {this.after_get_infos_of_solulive_fees(response)})
                    .catch((error : AxiosError<ErrorResponse>) => { this.after_get_infos_of_solulive_fees_error(error)})
                
                } else {
                    alert('Veuillez séléctionner au moins 1 commerçant')
                }
                
            } else {
                this.$router.push('/login');
            }
        },

        after_get_infos_of_solulive_fees(response : AxiosResponse<GetInfosOfSoluliveFeesSuccessResponse>) {

            const token_for_gestion = response.data.token_for_gestion

            if (token_for_gestion !== undefined && token_for_gestion !== null) {
                localStorage.setItem( 'token_for_gestion', token_for_gestion)
            }

            this.feesDataSolulive = response.data.feesDataSolulive
            // call method to prepare data for chart
            this.data_for_solulive_fees_chart()

        },

        after_get_infos_of_solulive_fees_error(error : AxiosError<ErrorResponse>) {

            if(error.response?.data.status === "error" ) {
                
                let message : string = error.response?.data.error_code;

                if (message === "token_expired") {

                    alert( "Votre cession est expirée, veuillez vous reconnecter.")
                    localStorage.removeItem( 'token_for_gestion')
                    window.location.reload();

                } else if (message === "invalid_token") {

                    window.location.reload();
                    localStorage.removeItem( 'token_for_gestion')
                    
                } else if (message === "request_failed") {
                    alert('Une erreur est survenue, veuillez réessayer')
                }
            }
        },


        data_for_fees_chart() {

            // 1st step : order fees data by timestamp

            let feesDataOrderByTimestamp : { [key: number]: {feesDelivery : number, feesTransactions : number, feesOthers: number} } = {};
            
            
            if (this.feesData && Object.keys(this.feesData).length > 0) {


                Object.entries(this.feesData.fees).forEach(([feesTypeKey, feesTypeValue])  => {
                    
                    feesTypeValue.forEach(fee => {

                        let timestamp : number = fee.timestampFee
                        let amountToPayHt = fee.amountToPayHt

                        // Verify if timestamp is not include in the object
                        if(!(timestamp in feesDataOrderByTimestamp)) {
                            
                            feesDataOrderByTimestamp[timestamp] = {
                                feesDelivery: 0,
                                feesTransactions: 0,
                                feesOthers: 0,
                            }
                        }    

                        // Check feesTypeKey and update the corresponding value
                        if(feesTypeKey === "feesDelivery") {
                            feesDataOrderByTimestamp[timestamp].feesDelivery += amountToPayHt;
                        } else if (feesTypeKey === "feesTransactions") {
                            feesDataOrderByTimestamp[timestamp].feesTransactions += amountToPayHt;
                        } else if (feesTypeKey === "feesOthers") {
                            feesDataOrderByTimestamp[timestamp].feesOthers += amountToPayHt;
                        }
                    })
                });

                // Format amounts to two decimal places to address floating-point precision issues
                for (let timestamp in feesDataOrderByTimestamp) {
                    // Use toFixed(2) to ensure exactly two decimal places, then parseFloat to convert back to a number
                    feesDataOrderByTimestamp[timestamp].feesDelivery = parseFloat((feesDataOrderByTimestamp[timestamp].feesDelivery).toFixed(2));
                    feesDataOrderByTimestamp[timestamp].feesTransactions = parseFloat((feesDataOrderByTimestamp[timestamp].feesTransactions).toFixed(2));
                    feesDataOrderByTimestamp[timestamp].feesOthers = parseFloat((feesDataOrderByTimestamp[timestamp].feesOthers).toFixed(2));
                }

                // 2nd step : order fees data by day and month

                let feesDataForChart: Array<feesOfTheDay> = []

                let start_timestamp = this.start_timestamp
                let end_timestamp = this.end_timestamp

                // Obtain all dates between start and end timestamps
                let timestampsBetweenDatesArray = this.getTimestampsBetweenDates(start_timestamp, end_timestamp)

                timestampsBetweenDatesArray.forEach((timestamp, index) => {
                    
                    const dateString = this.timestamp_to_date(timestamp, 'dd/mm')!
                    const feesDataOfDay: feesOfTheDay = {
                        dateString: dateString,
                        totalFeesOfTheDay: 0,
                        totalFeesDeliveryOfTheDay: 0,
                        totalFeesTransactionsOfTheDay: 0,
                        totalFeesOthersOfTheDay: 0,
                        totalCumulatedFees: 0,
                        totalCumulatedFeesDelivery: 0,
                        totalCumulatedFeesTransactions: 0,
                        totalCumulatedFeesOthers: 0,
                    };                    

                    Object.entries(feesDataOrderByTimestamp).forEach(([timestampKey, value]) => {

                        const dateKey = this.timestamp_to_date(Number(timestampKey), 'dd/mm')

                        if (dateKey === dateString) {

                            feesDataOfDay.totalFeesOfTheDay += value.feesDelivery + value.feesTransactions + value.feesOthers
                            feesDataOfDay.totalFeesDeliveryOfTheDay += value.feesDelivery
                            feesDataOfDay.totalFeesTransactionsOfTheDay += value.feesTransactions
                            feesDataOfDay.totalFeesOthersOfTheDay += value.feesOthers

                            // Add feesData to cumulative
                            feesDataOfDay.totalCumulatedFees = feesDataOfDay.totalFeesOfTheDay;
                            feesDataOfDay.totalCumulatedFeesDelivery = feesDataOfDay.totalFeesDeliveryOfTheDay;
                            feesDataOfDay.totalCumulatedFeesTransactions = feesDataOfDay.totalFeesTransactionsOfTheDay;
                            feesDataOfDay.totalCumulatedFeesOthers = feesDataOfDay.totalFeesOthersOfTheDay;
                        }                                                      
                    })
               
                    if (index > 0) {

                        feesDataOfDay.totalCumulatedFees += feesDataForChart[index - 1].totalCumulatedFees;
                        feesDataOfDay.totalCumulatedFeesDelivery += feesDataForChart[index - 1].totalCumulatedFeesDelivery;
                        feesDataOfDay.totalCumulatedFeesTransactions += feesDataForChart[index - 1].totalCumulatedFeesTransactions;
                        feesDataOfDay.totalCumulatedFeesOthers += feesDataForChart[index - 1].totalCumulatedFeesOthers;
                    }

                    // Apply toFixed to all number properties of feesDataOfDay
                    feesDataOfDay.totalFeesOfTheDay = Number(feesDataOfDay.totalFeesOfTheDay.toFixed(2));
                    feesDataOfDay.totalFeesDeliveryOfTheDay = Number(feesDataOfDay.totalFeesDeliveryOfTheDay.toFixed(2));
                    feesDataOfDay.totalFeesTransactionsOfTheDay = Number(feesDataOfDay.totalFeesTransactionsOfTheDay.toFixed(2));
                    feesDataOfDay.totalFeesOthersOfTheDay = Number(feesDataOfDay.totalFeesOthersOfTheDay.toFixed(2));
                    feesDataOfDay.totalCumulatedFees = Number(feesDataOfDay.totalCumulatedFees.toFixed(2));
                    feesDataOfDay.totalCumulatedFeesDelivery = Number(feesDataOfDay.totalCumulatedFeesDelivery.toFixed(2));
                    feesDataOfDay.totalCumulatedFeesTransactions = Number(feesDataOfDay.totalCumulatedFeesTransactions.toFixed(2));
                    feesDataOfDay.totalCumulatedFeesOthers = Number(feesDataOfDay.totalCumulatedFeesOthers.toFixed(2));
                        
                    feesDataForChart.push(feesDataOfDay);

                });
                
                this.feesDataForChart = feesDataForChart

                //update totals vue variable to display them
                const lastIndex = feesDataForChart.length - 1
                const totalFees = feesDataForChart[lastIndex].totalCumulatedFees
                const totalFeesDelivery = feesDataForChart[lastIndex].totalCumulatedFeesDelivery
                const totalFeesTransactions = feesDataForChart[lastIndex].totalCumulatedFeesTransactions
                const totalFeesOthers = feesDataForChart[lastIndex].totalCumulatedFeesOthers
                this.totalFees = totalFees
                this.totalFeesDelivery = totalFeesDelivery
                this.totalFeesTransactions = totalFeesTransactions
                this.totalFeesOthers = totalFeesOthers
            }
        },
        data_for_solulive_fees_chart() {

            // 1st step : order solulive fees data by timestamp

            let soluliveFeesDataOrderByTimestamp : { [key: number]: {feesDeliverySolulive : number, feesSmsSolulive : number, feesGoCardLessSolulive : number, feesSendCloudLabelSolulive : number} } = {};
                        
            if (this.feesDataSolulive && Object.keys(this.feesDataSolulive).length > 0) {

                Object.entries(this.feesDataSolulive.feesSolulive).forEach(([feesTypeKey, feesTypeValue])  => {

                    feesTypeValue.forEach(fee => {

                        let timestamp : number = fee.timestampFee
                        let amountToPayHt = fee.amountToPayHt

                        // Verify if timestamp is not include in the object
                        if(!(timestamp in soluliveFeesDataOrderByTimestamp)) {
                            
                            soluliveFeesDataOrderByTimestamp[timestamp] = {
                                feesDeliverySolulive: 0,
                                feesSmsSolulive: 0,
                                feesGoCardLessSolulive: 0,
                                feesSendCloudLabelSolulive : 0,
                            }
                        }    

                        // Check feesTypeKey and update the corresponding value
                        if(feesTypeKey === "feesDeliverySolulive") {
                            soluliveFeesDataOrderByTimestamp[timestamp].feesDeliverySolulive += amountToPayHt;
                        } else if (feesTypeKey === "feesSmsSolulive") {
                            soluliveFeesDataOrderByTimestamp[timestamp].feesSmsSolulive += amountToPayHt;
                        } else if (feesTypeKey === "feesGoCardLessSolulive") {
                            soluliveFeesDataOrderByTimestamp[timestamp].feesGoCardLessSolulive += amountToPayHt;
                        } else if (feesTypeKey === "feesSendCloudLabelSolulive") {
                            soluliveFeesDataOrderByTimestamp[timestamp].feesSendCloudLabelSolulive += amountToPayHt;
                        }
                    }) 
                });

                // Format amounts to two decimal places to address floating-point precision issues
                for (let timestamp in soluliveFeesDataOrderByTimestamp) {
                    // Use toFixed(2) to ensure exactly two decimal places, then parseFloat to convert back to a number
                    soluliveFeesDataOrderByTimestamp[timestamp].feesDeliverySolulive = parseFloat((soluliveFeesDataOrderByTimestamp[timestamp].feesDeliverySolulive).toFixed(2));
                }

                // 2nd step : order fees data by day and month

                let soluliveFeesDataForChart: Array<soluliveFeesOfTheDay> = []

                const start_timestamp = this.start_timestamp
                const end_timestamp = this.end_timestamp

                // Obtain all dates between start and end timestamps
                let timestampsBetweenDatesArray = this.getTimestampsBetweenDates(start_timestamp, end_timestamp)

                timestampsBetweenDatesArray.forEach((timestamp, index) => {

                    const dateString = this.timestamp_to_date(timestamp, 'dd/mm')!

                    const soluliveFeesDataOfDay: soluliveFeesOfTheDay = {
                        dateString: dateString,
                        soluliveTotalFeesOfTheDay : 0,
                        soluliveTotalFeesDeliveryOfTheDay : 0,
                        soluliveTotalFeesSmsOfTheDay : 0,
                        soluliveTotalFeesGoCardLessOfTheDay : 0,
                        soluliveTotalFeesSendCloudLabelOfTheDay : 0,
                        soluliveTotalCumulatedFees : 0,
                        soluliveTotalCumulatedFeesDelivery : 0,
                        soluliveTotalCumulatedFeesSms : 0,
                        soluliveTotalCumulatedFeesGoCardLess : 0,
                        soluliveTotalCumulatedFeesSendCloudLabel : 0,
                    }; 

                    Object.entries(soluliveFeesDataOrderByTimestamp).forEach(([timestampKey, value]) => {

                        const dateKey = this.timestamp_to_date(Number(timestampKey), 'dd/mm')

                        if (dateKey === dateString) {

                            soluliveFeesDataOfDay.soluliveTotalFeesOfTheDay += value.feesDeliverySolulive + value.feesSmsSolulive +  value.feesGoCardLessSolulive + value.feesSendCloudLabelSolulive
                            soluliveFeesDataOfDay.soluliveTotalFeesDeliveryOfTheDay += value.feesDeliverySolulive
                            soluliveFeesDataOfDay.soluliveTotalFeesSmsOfTheDay += value.feesSmsSolulive
                            soluliveFeesDataOfDay.soluliveTotalFeesGoCardLessOfTheDay += value.feesGoCardLessSolulive
                            soluliveFeesDataOfDay.soluliveTotalFeesSendCloudLabelOfTheDay += value.feesSendCloudLabelSolulive

                            // Add feesData to cumulative
                            soluliveFeesDataOfDay.soluliveTotalCumulatedFees = soluliveFeesDataOfDay.soluliveTotalFeesOfTheDay;
                            soluliveFeesDataOfDay.soluliveTotalCumulatedFeesDelivery = soluliveFeesDataOfDay.soluliveTotalFeesDeliveryOfTheDay;
                            soluliveFeesDataOfDay.soluliveTotalCumulatedFeesSms = soluliveFeesDataOfDay.soluliveTotalFeesSmsOfTheDay;
                            soluliveFeesDataOfDay.soluliveTotalCumulatedFeesGoCardLess = soluliveFeesDataOfDay.soluliveTotalFeesGoCardLessOfTheDay;
                            soluliveFeesDataOfDay.soluliveTotalCumulatedFeesSendCloudLabel = soluliveFeesDataOfDay.soluliveTotalFeesSendCloudLabelOfTheDay;
                        }                                                      
                    })

                    if (index > 0) {

                        soluliveFeesDataOfDay.soluliveTotalCumulatedFees += soluliveFeesDataForChart[index - 1].soluliveTotalCumulatedFees;
                        soluliveFeesDataOfDay.soluliveTotalCumulatedFeesDelivery += soluliveFeesDataForChart[index - 1].soluliveTotalCumulatedFeesDelivery;
                        soluliveFeesDataOfDay.soluliveTotalCumulatedFeesSms += soluliveFeesDataForChart[index - 1].soluliveTotalCumulatedFeesSms;
                        soluliveFeesDataOfDay.soluliveTotalCumulatedFeesGoCardLess += soluliveFeesDataForChart[index - 1].soluliveTotalCumulatedFeesGoCardLess;
                        soluliveFeesDataOfDay.soluliveTotalCumulatedFeesSendCloudLabel += soluliveFeesDataForChart[index - 1].soluliveTotalCumulatedFeesSendCloudLabel;
                    }

                    // Apply math.round to all number properties of soluliveFeesDataOfDay
                    soluliveFeesDataOfDay.soluliveTotalFeesOfTheDay = Number(soluliveFeesDataOfDay.soluliveTotalFeesOfTheDay.toFixed(2));
                    soluliveFeesDataOfDay.soluliveTotalFeesDeliveryOfTheDay = Number(soluliveFeesDataOfDay.soluliveTotalFeesDeliveryOfTheDay.toFixed(2));
                    soluliveFeesDataOfDay.soluliveTotalFeesSmsOfTheDay = Number(soluliveFeesDataOfDay.soluliveTotalFeesSmsOfTheDay.toFixed(2));
                    soluliveFeesDataOfDay.soluliveTotalFeesGoCardLessOfTheDay = Number(soluliveFeesDataOfDay.soluliveTotalFeesGoCardLessOfTheDay.toFixed(2));
                    soluliveFeesDataOfDay.soluliveTotalFeesSendCloudLabelOfTheDay = Number(soluliveFeesDataOfDay.soluliveTotalFeesSendCloudLabelOfTheDay.toFixed(2));

                    soluliveFeesDataOfDay.soluliveTotalCumulatedFees = Number(soluliveFeesDataOfDay.soluliveTotalCumulatedFees.toFixed(2));
                    soluliveFeesDataOfDay.soluliveTotalCumulatedFeesDelivery = Number(soluliveFeesDataOfDay.soluliveTotalCumulatedFeesDelivery.toFixed(2));
                    soluliveFeesDataOfDay.soluliveTotalCumulatedFeesSms = Number(soluliveFeesDataOfDay.soluliveTotalCumulatedFeesSms.toFixed(2));
                    soluliveFeesDataOfDay.soluliveTotalCumulatedFeesGoCardLess = Number(soluliveFeesDataOfDay.soluliveTotalCumulatedFeesGoCardLess.toFixed(2));
                    soluliveFeesDataOfDay.soluliveTotalCumulatedFeesSendCloudLabel = Number(soluliveFeesDataOfDay.soluliveTotalCumulatedFeesSendCloudLabel.toFixed(2));     

                    soluliveFeesDataForChart.push(soluliveFeesDataOfDay);
                });

                this.soluliveFeesDataForChart = soluliveFeesDataForChart

                //update totals vue variable to display them
                const lastIndex = soluliveFeesDataForChart.length - 1
                const soluliveTotalFees = soluliveFeesDataForChart[lastIndex].soluliveTotalCumulatedFees
                const soluliveTotalFeesDelivery = soluliveFeesDataForChart[lastIndex].soluliveTotalCumulatedFeesDelivery
                const soluliveTotalFeesSms = soluliveFeesDataForChart[lastIndex].soluliveTotalCumulatedFeesSms
                const soluliveTotalFeesGoCardLess = soluliveFeesDataForChart[lastIndex].soluliveTotalCumulatedFeesGoCardLess
                const soluliveTotalFeesSendCloudLabel = soluliveFeesDataForChart[lastIndex].soluliveTotalCumulatedFeesSendCloudLabel

                this.soluliveTotalFees = soluliveTotalFees
                this.soluliveTotalFeesDelivery = soluliveTotalFeesDelivery
                this.soluliveTotalFeesSms = soluliveTotalFeesSms
                this.soluliveTotalFeesGoCardLess = soluliveTotalFeesGoCardLess
                this.soluliveTotalFeesSendCloudLabel = soluliveTotalFeesSendCloudLabel

                this.data_for_solulive_gross_margin()

            }
        },
        data_for_solulive_gross_margin(){

            if(this.soluliveFeesDataForChart.length) {

                let soluliveGrossMarginForChart: Array<soluliveGrossMarginOfTheDay> = []

                this.soluliveFeesDataForChart.forEach((feeData, index) => {
                    
                    const dateString = feeData.dateString

                    const totalFeesOfTheDay = this.feesDataForChart[index].totalFeesOfTheDay
                    const soluliveTotalFeesOfTheDay = feeData.soluliveTotalFeesOfTheDay

                    const totalCumulatedFees = this.feesDataForChart[index].totalCumulatedFees
                    const soluliveTotalCumulatedFees = feeData.soluliveTotalCumulatedFees

                    const soluliveGrossMarginOfTheDay : soluliveGrossMarginOfTheDay = {
                        dateString: dateString,
                        grossMarginOfTheDay: Number((totalFeesOfTheDay - soluliveTotalFeesOfTheDay).toFixed(2)),
                        grossMarginCumulated: Number((totalCumulatedFees - soluliveTotalCumulatedFees).toFixed(2)),
                    }

                    soluliveGrossMarginForChart.push(soluliveGrossMarginOfTheDay)
                });

                this.soluliveGrossMarginForChart = soluliveGrossMarginForChart

                //update totalGrossMargin vue variable to display it
                const lastIndex = soluliveGrossMarginForChart.length - 1
                const totalGrossMargin = soluliveGrossMarginForChart[lastIndex].grossMarginCumulated
                this.totalGrossMargin = totalGrossMargin

                //display chart after requests
                this.displayChart = true
            }
        },
        update_date_filter() {
            
            // Get date and split the string to replace - by / and put it in the correct order
            const startDateElement = document.getElementById('start_period_date') as HTMLInputElement
            const endDateElement = document.getElementById('end_period_date') as HTMLInputElement

            if (startDateElement && endDateElement) {
                const startDate = startDateElement.value
                const endDate = endDateElement.value

                

                this.startPeriodDate = startDate
                this.endPeriodDate = endDate

                // Display of invoice with start and end timestamps
                this.get_timestamps_for_filter()
                // Get infos of fees
                this.get_infos_of_fees()
                this.get_infos_of_solulive_fees()
                // const feesBarChart: any = this.$refs.feesBarChart; // Déclarer comme de type any
                // feesBarChart.updateChart();

            }
        },
        updateAllCheckedNames() {
            this.checkedNames = []

            for (const seller of this.sellersList ) {

                seller.isChecked = this.selectAll

                if(seller.isChecked === true) {
                    this.updateCheckedNames(seller)
                }
                
            }
        },
        updateCheckedNames (seller: Seller) {

            if ( this.checkedNames.includes(seller.techName) ){

                let index = this.checkedNames.indexOf(seller.techName)
                this.checkedNames.splice(index, 1)

            } else {

                this.checkedNames.push(seller.techName)
            }

            if(seller.isChecked === false){
                this.selectAll = false
            }
        },
        getTimestampsBetweenDates(startDate : number, endDate : number) {

            const start = startOfDay(new Date(startDate * 1000));
            const end = endOfDay(new Date(endDate * 1000));

            const days = eachDayOfInterval({ start, end });

            return days.map(day => getTime(day) / 1000);
        },

        timestamp_to_date(timestamp: number, format: string){
            let date = new Date(timestamp * 1000);

            if (format === "dd/mm/yyyy") {
                let day : number | string = date.getDate();
                if (day < 10) {
                    day = "0"+day;
                }
                let month : number | string = date.getMonth()+1;
                if (month < 10) {
                    month = "0"+month;
                }
                let year = date.getFullYear();
                
                return day+"/"+month+"/"+year;
            }
            else if (format === "dd/mm") {
                let day : number |string = date.getDate();
                if (day < 10) {
                    day = "0"+day;
                }
                let month : number | string = date.getMonth()+1;
                if (month < 10) {
                    month = "0"+month;
                }
                return day+"/"+month;
            }
            else if (format === "dd_mm_yyyy") {
                let day : number | string = date.getDate();
                if (day < 10) {
                    day = "0"+day;
                }
                let month : number | string = date.getMonth()+1;
                if (month < 10) {
                    month = "0"+month;
                }
                let year = date.getFullYear();
                
                return day+"_"+month+"_"+year;
            }
            else if (format === "literal_ds_dn_ms") {

                let today  = new Date(timestamp* 1000);

                let day_string = this.toTitleCase(today.toLocaleDateString("fr-FR", { weekday: 'long' } ));
                let day_number = today.toLocaleDateString("fr-FR", { day: 'numeric' });
                let month_string = today.toLocaleDateString("fr-FR", { month: 'long' });

                let the_date = day_string + " " + day_number + " " + month_string;

                return the_date;
            }
            else if (format === "literal_ds_dn_mn_yn") {

                let today = new Date(timestamp* 1000);

                let day_string = this.toTitleCase(today.toLocaleDateString("fr-FR", { weekday: 'long' } ));
                let day_number = today.toLocaleDateString("fr-FR", { day: 'numeric' });
                let month_number = today.toLocaleDateString("fr-FR", { month: 'numeric' });
                let year_number = today.toLocaleDateString("fr-FR", { year: 'numeric' });

                let the_date = day_string + " " + day_number + "/" + month_number + "/" + year_number;

                return the_date;
            }
            else if (format === "literal_dn_ms_yn") {

                let today  = new Date(timestamp* 1000);

                let day_number = today.toLocaleDateString("fr-FR", { day: 'numeric' });
                let month_string = today.toLocaleDateString("fr-FR", { month: 'long' });
                let year_number = today.toLocaleDateString("fr-FR", { year: 'numeric' });

                let the_date = day_number + " " + month_string + " " + year_number;

                return the_date;
            }
            else if ( format === "hh:mm") {

                let hours : number | string = date.getHours();
                if (hours < 10) {
                    hours = "0"+hours;
                }
                let minutes : number | string = date.getMinutes();
                if (minutes < 10) {
                    minutes = "0"+minutes;
                }

                return hours+"h"+minutes;
            }
        },
        date_to_timestamp(year: number, month : number, day : number){
            const hour : number = parseInt("00", 10)
            const minute : number = parseInt("00", 10)
            const second : number = parseInt("00", 10)

            let date = new Date(Date.UTC(year,month-1,day,hour,minute,second));
            let timestamp = date.getTime()/1000;
            timestamp = timestamp+(date.getTimezoneOffset()*60);

            return timestamp;
        },
        toTitleCase(str : string) {
            return str.replace(
                /\w\S*/g,
                function(txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
            );
        },



    }
})
</script>

<style scoped>
@import "@/assets/scss/statistics.scss";
</style>